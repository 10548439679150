import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ReactPlayer from "react-player";

function debounce(fn, ms) {
	let timer;
	return () => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}

const Video = (props) => {
	const [size, setSize] = useState({ width: "100%", height: "100%" });
	const { src, displayMode, isMobile } = props;
	const video = useRef(null);
	const videoWrap = useRef(null);
	const player = useRef(null);
	const [isPlaying, setIsPlaying] = useState(true);
	const [progress, setProgress] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const prevTime = useRef(0);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const videoResize = (vid, videoWrap) => {
		const proportion = vid.videoWidth / vid.videoHeight;
		const isVertical =
			videoWrap.offsetHeight >= videoWrap.offsetWidth / proportion;
		const isVerticalM =
			videoWrap.offsetWidth >= videoWrap.offsetHeight * proportion;

		const getNewSize = () => {
			if (Number.isNaN(proportion)) {
				return size;
			}
			if (proportion > 1) {
				return {
					width: isVertical
						? videoWrap.offsetHeight * proportion
						: videoWrap.offsetWidth,
					height: isVertical
						? videoWrap.offsetHeight
						: videoWrap.offsetWidth / proportion,
				};
			}
			return {
				width: !isVerticalM
					? videoWrap.offsetHeight / proportion
					: videoWrap.offsetWidth,
				height: !isVerticalM
					? videoWrap.offsetHeight
					: videoWrap.offsetWidth / proportion,
			};
		};
		setSize(getNewSize());
	};

	useEffect(() => {
		if (
			displayMode === "full_screen" &&
			video &&
			video.current &&
			videoWrap &&
			videoWrap.current
		) {
			const debouncedVideoResize = debounce(
				() => videoResize(video.current, videoWrap.current),
				100
			);
			window.addEventListener("resize", debouncedVideoResize);
			return () => {
				window.removeEventListener("resize", debouncedVideoResize);
			};
		}
	}, []);

	useEffect(() => {
		if (progress) {
			if (!isVisible) {
				setTimeout(() => {
					setIsVisible(true);
				}, 1000);
			}
			if (
				isSafari &&
				isMobile &&
				isPlaying &&
				progress.loaded === 1 &&
				prevTime.current < progress.playedSeconds &&
				prevTime.current + 0.8 > progress.playedSeconds &&
				prevTime.current + 0.8 <= progress.loadedSeconds &&
				progress.playedSeconds > 0.8
			) {
				player.current.seekTo(progress.playedSeconds - 1);
				prevTime.current = progress.playedSeconds - 1;
			} else {
				prevTime.current = progress.playedSeconds;
			}
		}
		if (
			typeof size.width === "string" &&
			displayMode === "full_screen" &&
			video.current &&
			videoWrap.current
		) {
			videoResize(video.current, videoWrap.current);
		}
	}, [progress]);

	const handleActive = () => {
		setIsPlaying(true);
	};
	const handleInActive = () => {
		setIsPlaying(false);
	};

	useEffect(() => {
		if (isSafari && isMobile) {
			window.addEventListener("focus", handleActive);
			window.addEventListener("blur", handleInActive);
		}
		return () => {
			window.removeEventListener("focus", handleActive);
			window.removeEventListener("blur", handleInActive);
		};
	}, []);

	const css = `
    .cover .reactPlayer {
      opacity: ${isVisible ? 1 : 0};
    }
    .cover .reactPlayer video {
      width: ${size.width}px !important;
      height: ${size.height}px !important;
     }
  `;

	return (
		<div
			className={`${displayMode === "full_screen" ? "cover" : ""} video-bg`}
			ref={videoWrap}
		>
			<style>{css}</style>

			<ReactPlayer
				loop
				muted
				autoPlay
				playsinline
				playing={isPlaying}
				className="reactPlayer"
				width={displayMode === "full_screen" ? size.width : "100%"}
				height={displayMode === "full_screen" ? size.height : "100%"}
				onReady={(player) => {
					video.current = player.getInternalPlayer();
				}}
				onProgress={(e) => setProgress(e)}
				ref={player}
				light={false}
				pip={false}
				url={src}
				controls={false}
				stopOnUnmount={false}
				config={{
					file: {
						forceHLS: !isSafari,
						forceAudio: false,
						attributes: {
							disableRemotePlayback: true,
							disablePictureInPicture: true,
							onLoadedMetadata: (e) => {
								videoResize(e.target, videoWrap.current);
							},
							onPlay() {
								videoResize(video.current, videoWrap.current);
							},
							onCanPlay() {
								videoResize(video.current, videoWrap.current);
							},
						},
					},
				}}
			/>
		</div>
	);
};

const SlideShow = (props) => {
	const { slides, displayMode } = props;
	const [css, setCss] = useState("");
	const settings = {
		fade: true,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 5000,
	};

	useEffect(() => {
		if (
			displayMode === "infinite_scroll" ||
			displayMode === "full_infinite_scroll"
		) {
			const img = new Image();
			img.onload = function () {
				setCss(`
        .infinite-scroll {
          background-position: 0 0;
		  ${displayMode === "full_infinite_scroll" ? "background-size: auto 100%;" : ""}
          background-repeat: repeat;
          animation: animatedBackground ${Math.round(
						this.width / 10
					)}s linear infinite;
        }
        @keyframes animatedBackground {
          from {
              background-position: -${this.width * 3}px 0;
            }
          to {
              background-position: 0 0;
            }
        }
        `);
			};
			img.src = slides[0];
		}
	}, []);

	if (
		displayMode === "infinite_scroll" ||
		displayMode === "full_infinite_scroll"
	) {
		return (
			<div
				className="infinite-scroll"
				style={{ backgroundImage: `url(${slides[0]})` }}
			>
				<style>{css}</style>
			</div>
		);
	}

	return (
		<Slider className="banner-slider" {...settings}>
			{slides.map((slide) => (
				<div className="slide-wrap" key={slide}>
					<div
						className="slide"
						style={{
							backgroundImage: `url(${slide})`,
							backgroundRepeat:
								displayMode === "repeat" ? "repeat" : "no-repeat",
							backgroundSize:
								displayMode === "repeat"
									? "auto"
									: displayMode === "fit_screen"
									? "contain"
									: "cover",
						}}
					/>
				</div>
			))}
		</Slider>
	);
};

const Bg = (props) => {
	const [dimensions, setDimensions] = useState(window.innerWidth);
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions(window.innerWidth);
		}, 100);
		window.addEventListener("resize", debouncedHandleResize);
		return () => {
			window.removeEventListener("resize", debouncedHandleResize);
		};
	});

	const {
		slideshow,
		slideshowMobile,
		slideshowDisplayMode,
		slideshowDisplayModeMobile,
		useVideoAsBackground,
		useVideoAsBackgroundMobile,
		videoDisplayMode,
		videoDisplayModeMobile,
		videoMobile,
		videoUrl,
	} = props;

	return (
		<div className="bg-wrap">
			{(useVideoAsBackground && dimensions > 991) ||
			(useVideoAsBackgroundMobile === "on" && dimensions < 992) ? (
				<Video
					src={dimensions < 992 ? videoMobile || videoUrl : videoUrl}
					displayMode={
						dimensions < 992 ? videoDisplayModeMobile : videoDisplayMode
					}
					isMobile={dimensions < 992}
				/>
			) : (
				<SlideShow
					slides={dimensions < 992 ? slideshowMobile || slideshow : slideshow}
					displayMode={
						dimensions < 992 ? slideshowDisplayModeMobile : slideshowDisplayMode
					}
				/>
			)}
		</div>
	);
};

ReactDOM.render(<Bg {...settings} />, document.getElementById("landing-bg"));
